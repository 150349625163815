
import { Component, namespace, Prop, Vue } from "nuxt-property-decorator";
import { ButtonText, PaymentTypes, Products } from "~/operations/messages";

import RouterLink from "~/components/atoms/text/RouterLink.vue";
import HeaderSubMenu from "~/components/molecules/nav/HeaderSubMenu.vue";
import ReducedPriceComparison from "~/components/organisms/form/ReducedPriceComparison.vue";
import sendGtmEvent from "~/operations/send-gtm-event";
import { getLogoUrl, getMkLogin } from "~/operations/shared";

const UserModule = namespace("user");

@Component({
  components: {
    RouterLink,
    ReducedPriceComparison,
    HeaderSubMenu,
  },
})
export default class Header extends Vue {
  @Prop({ required: false }) logoSrc: string;
  @Prop({ required: false }) logoAlt: string;
  @Prop({ default: "default" }) variant: string;

  @UserModule.Getter("isLoggedIn")
  isLoggedIn: boolean;

  @UserModule.Getter("user")
  userData: any;

  ButtonText = ButtonText;
  Products = Products;
  PaymentTypes = PaymentTypes;

  menuItems = [
    {
      name: "Heizölpreise",
      title: "Zu den Heizölpreisen",
      link: { path: "/heizoelpreise" },
      externalLink: false,
      assetsIconFile: "tint-solid.svg",
    },
    {
      name: "Benzinpreise",
      title: "Zu den Benzinpreisen",
      link: "/benzinpreise",
      externalLink: true,
      assetsIconFile: "gas-pump-solid.svg",
    },
    {
      name: "Ölpreis",
      title: "Zum Ölpreis",
      link: { path: "/oelpreis" },
      externalLink: false,
      assetsIconFile: "coins-solid.svg",
    },
  ];

  serviceMenuItems = [
    {
      title: "Heizöl-Infos",
      variant: "menu",
      children: [
        {
          name: `${PaymentTypes.guaranteedPayment}`,
          title: "Heizöl auf Raten kaufen",
          href: { path: "/infos/ratenkauf" },
          externalLink: false,
        },
        {
          name: "CO2-Abgabe",
          title: "Erhalten Sie Informationen zur CO2-Steuer",
          href: { path: "/infos/co2-steuer" },
          externalLink: false,
        },
        {
          name: "Heizöl-Sorten",
          title: "Erfahren Sie mehr über die verschiedenen Heizöl-Sorten",
          href: { path: "/infos/heizoel-sorten" },
          externalLink: false,
        },
        {
          name: "Heizölverbrauch",
          title:
            "Berechnen Sie ihren Heizöl-Verbrauch mit unserem Heizöl-Verbrauchsrechner",
          href: { path: "/services/heizoelverbrauch" },
          externalLink: false,
        },
        {
          name: "Heizöl-News",
          title: "News rund um das Thema Heizöl",
          href: { path: "/news" },
          externalLink: false,
        },
        {
          name: "HVO Heizöl",
          title: "Überblick über das Thema HVO Heizöl",
          href: { path: "/infos/hvo-heizoel" },
          externalLink: false,
        },
      ],
    },
    {
      title: "Service",
      variant: "menu",
      children: [
        {
          name: "Hilfebereich",
          title: "Zum Hilfebereich von esyoil",
          href: { path: "/infos/faq" },
          externalLink: false,
        },
        {
          name: "Tipps & Tricks",
          title: "Zu Tipps & Tricks von esyoil",
          href: { path: "/services/tipps-tricks" },
          externalLink: false,
        },
        {
          name: Products.mainApp,
          title: `Infos zu unserer ${Products.mainApp}`,
          href: { path: "/services/app" },
          externalLink: false,
        },
        {
          name: "Ölheizung",
          title: "Informationen zu Ölheizungen",
          href: { path: "/services/oelheizung" },
          externalLink: false,
        },
      ],
    },
    {
      title: "Messen",
      variant: "banner",
      children: [
        {
          name: "Jetzt kaufen",
          title: `${Products.ePeilstab} bestellen und Füllstand überwachen`,
          href: { path: "/heizoeltank/e-peilstab" },
          externalLink: false,
          image: {
            url: require("~/assets/images/products/ep-plus.webp"),
            fallbackUrl: require("~/assets/images/products/ep-plus.jpg"),
            alt: `Abbildung ${Products.ePeilstab}`,
            width: "600",
            height: "600",
          },
        },
      ],
    },
  ];

  loginItems = [
    {
      name: "...",
      title: "Mein Konto",
      link: getMkLogin(),
      externalLink: true,
      assetsIconFile: "user-circle-solid.svg",
    },
  ];

  menu: { visible: boolean } = {
    visible: false,
  };

  serviceSubMenu: { visible: boolean } = {
    visible: false,
  };

  form: { visible: boolean } = {
    visible: true,
  };

  mounted() {
    document.addEventListener("click", this.closeMenus);
    // update user-login state
    this.loginItems[0].name = this.isLoggedIn
      ? this.userData.email
      : "Anmelden";
  }

  beforeDestroy() {
    document.removeEventListener("click", this.closeMenus);
  }

  closeMenus(e: Event) {
    if (
      window.innerWidth < 1100 &&
      this.menu.visible &&
      !this.$el.contains(e.target as Node)
    ) {
      this.menu.visible = false;
      this.serviceSubMenu.visible = false;
    } else if (
      !this.$el.contains(e.target as Node) &&
      this.serviceSubMenu.visible
    ) {
      this.serviceSubMenu.visible = false;
    } else {
      return;
    }
  }

  get colorClass() {
    return process.env.MAIN_ESYOIL.includes(".com") ? "" : "io-blue";
  }

  toggleMainMenu(event) {
    this.menu.visible = !this.menu.visible;
    this.serviceSubMenu.visible = false;

    sendGtmEvent(this, {
      category: "Navigation",
      label: "Nav Burger Icon",
      action: "click",
    });
  }

  toggleServiceSubMainMenu() {
    this.serviceSubMenu.visible = !this.serviceSubMenu.visible;
  }

  toggleForm() {
    this.form.visible = !this.form.visible;

    sendGtmEvent(this, {
      category: "Navigation",
      label: this.form.visible ? "Nav PV aufklappen" : "Nav PV zuklappen",
      action: "click",
    });
  }

  get logoUrl() {
    return getLogoUrl();
  }
}
